import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import "../data/sections-fragment";
export const query = graphql`
  query {
    mdx(slug: { eq: "garagen-nach-mass" }) {
      slug
      frontmatter {
        title
        footer {
          showContactFooter: show_contact_footer
          showContactColumn: show_contact_footer
        }
        backgrounds {
          ...BackgroundFragment
        }
        sections {
          ...MdxFrontmatterSectionsFields
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Garagen nach Maß",
  "backgrounds": [{
    "rows": "1 / 4",
    "gradient": "linear-gradient(to-b, gray.50, rgba(255,255,255,0))",
    "spacing": {
      "top": ["33vh", null, "35vh", null, "45vh"]
    }
  }],
  "sections": [{
    "type": "header",
    "header_type": "beta",
    "title": "Garagen nach Maß",
    "text": "Hier gehen wir den Weg mit Ihnen von der Planung bis zur Fertigung. Dabei lösen wir mit Ihnen jede technische Herausforderung und binden bestehende Pläne – zum Beispiel vom Architekten – gerne ein.",
    "image": {
      "src": "images/header-garagen-nach-mass.jpg",
      "alt": "Garagen nach Maß",
      "fit": "cover",
      "position": "10% 20%"
    }
  }, {
    "type": "advantages",
    "slug": "vorteile",
    "title": "Vorteile",
    "spacing": {
      "top": ["20", null, "32"],
      "bottom": ["40", null, "72"]
    },
    "text": "Wir bauen Ihre Garage nach Wunsch freistehend, ans Wohnhaus, in Hanglage, mit Eingangsüberdachung oder als Carport. Besonderen örtliche Gegebenheiten stellen wir uns und passen Ihre Garage an Nischen, Ecken und schräge Grundstücksgrenzen an. Auch eine überhohe Einfahrtshöhe oder die Notwendigkeit der Überfahrt können wir berücksichtigen und umsetzen.",
    "advantages": "* Eine ganzheitliche Betreuung auf Basis eines hohen technischen Know-Hows und langer Erfahrung in Bezug auf Machbarkeit von technische Sonderlösungen\n* Eine Umsetzung zum Festpreis, die Ihnen finanzielle Sicherheit gibt\n* Eine schnelle, fachgerechte Ausführung\n* Hochwertige Materialien und Baustoffe, die wir aus der Region beziehen\n* Ein Team von zuverlässigen Facharbeitern mit langjähriger Berufserfahrung"
  }, {
    "type": "references",
    "slug": "referenzprojekte",
    "title": "Referenzprojekte",
    "spacing": {
      "bottom": ["40", null, "72"]
    },
    "references": [{
      "image": {
        "src": "images/referenzen/projekt-1.jpg",
        "alt": "Garage mit Eingangsüberdachung"
      },
      "title": "Garage mit Eingangsüberdachung",
      "goal": "Architektonische Harmonie zwischen den Garagen und der eleganten Eingangsüberdachung erzeugen.",
      "location": "Bad Krozingen"
    }, {
      "image": {
        "src": "images/referenzen/projekt-2.jpg",
        "alt": "Garage mit Eingangsüberdachung und Carport mit Stützwänden"
      },
      "title": "Garage mit Eingangsüberdachung und Carport mit Stützwänden",
      "goal": "Hier wurde eine Gesamtlösung aus verschiedenen Elementen angestrebt. Umgesetzt wurde ein Garage, die den fehlenden Keller ersetzt, ein Carport für Autos und Fahrräder sowie eine Terrasse und Stützwände zum Nachbarn.",
      "location": "Ettenheim"
    }, {
      "image": {
        "src": "images/referenzen/projekt-3.jpg",
        "alt": "Garage mit Überdachung"
      },
      "title": "Garage mit Überdachung",
      "goal": "Den Unterstellplatz für das Wohnmobil - ein Anbau-Carport - optimal mit einer Garage kombinieren.",
      "location": "Freiburg"
    }, {
      "image": {
        "src": "images/referenzen/projekt-4.jpg",
        "alt": "Garage mit Carport und Abstellraum"
      },
      "title": "Garage mit Carport und Abstellraum",
      "goal": "Den Nutzraum für Autos, Motorrad, Roller, Reifen, Werkzeug und Sonstiges maximieren.",
      "location": "Waltershofen"
    }, {
      "image": {
        "src": "images/referenzen/projekt-5.jpg",
        "alt": "Freistehende Garage"
      },
      "title": "Freistehende Garage",
      "goal": "Ein sicherer Unterstellplatz für einen Wohnwagen bauen - realisiert durch ein speziell angefertigtes Dach - und die maximale baurechtliche Wandhöhe an der Grenze einhalten.",
      "location": "Mengen"
    }, {
      "image": {
        "src": "images/referenzen/projekt-6.jpg",
        "alt": "Garage mit Überdachung und Unterkellerung"
      },
      "title": "Garage mit Überdachung und Unterkellerung",
      "goal": "Den Nutzen durch eine Kombination aus Keller, Garage und Carport maximieren.",
      "location": "Schliengen"
    }, {
      "image": {
        "src": "images/referenzen/projekt-7.jpg",
        "alt": "Einzelgarage mit Überdachung und Sichtbeton-Fertigteiltreppe"
      },
      "title": "Einzelgarage mit Überdachung und Sichtbeton-Fertigteiltreppe",
      "goal": "Stauraum für den Fuhrpark der ganzen Familie schaffen und einen optisch schönen Eingangsbereich bauen. Dieser punktet mit einer Überdachung für den Schutz vor Witterung und einer eleganten Fertigteiltreppe aus eingefärbtem Sichtbeton.",
      "location": "Freiburg"
    }, {
      "image": {
        "src": "images/referenzen/projekt-8.jpg",
        "alt": "Garage mit Unterkellerung und Überdachung"
      },
      "title": "Garage mit Unterkellerung und Überdachung",
      "goal": "Raum für Vieles schaffen - zum Auftanken, Schlafen, Waschen und Kochen.",
      "location": "Leiselheim"
    }, {
      "image": {
        "src": "images/referenzen/projekt-9.jpg",
        "alt": "Freistehende Garage"
      },
      "title": "Freistehende Garage",
      "goal": "Verschiedene Anforderungen in eine Lösung packen: Sichtschutz, Lagerflächen, Holzstauraum und Garage."
    }, {
      "image": {
        "src": "images/referenzen/projekt-10.jpg",
        "alt": "Freistehende Garage mit Überdachung"
      },
      "title": "Freistehende Garage mit Überdachung",
      "goal": "Raum schaffen für viel mehr als eine Garage - für Hobby, Werkbank, Reifenlager und für eine geschützte Fläche zur Lagerung von Feuerholz.",
      "location": "Ebringen"
    }, {
      "image": {
        "src": "images/referenzen/projekt-11.jpg",
        "alt": "Garage am Wohnhaus"
      },
      "title": "Garage am Wohnhaus",
      "goal": "Platz für eine große Terrasse über der Garage gewinnen.",
      "location": "Murg-Hänner"
    }, {
      "image": {
        "src": "images/referenzen/projekt-12.jpg",
        "alt": "Garage am Wohnhaus mit Überdachung"
      },
      "title": "Garage am Wohnhaus mit Überdachung",
      "goal": "Einen optisch schönen Übergang zum Haus erzeugen, durch den man trocken und sicher zum Eingang kommt.",
      "location": "Wieslet"
    }]
  }, {
    "type": "details",
    "slug": "details",
    "title": "Details",
    "text": "Der Fokus auf das Detail macht das große Ganze erst rund. In unserer Planung legen wir Wert auf jede kleine Besonderheit, jede Facette, die zu einem perfekten Ergebnis beiträgt. Wir haben alles im Blick, was notwendig ist, um Ihre Wunschgarage zu bauen.",
    "background_image": {
      "src": "images/beton.jpg"
    },
    "details": ["src/data/garages/details/ueberdachung.yaml", "src/data/garages/details/versatz.yaml", "src/data/garages/details/unterkellerung.yaml", "src/data/garages/details/vordach.yaml", "src/data/garages/details/dachbegruenung.yaml", "src/data/garages/details/carport-stuetzen.yaml", "src/data/garages/details/entwaesserungsrinne.yaml", "src/data/garages/details/sichtbetontreppe.yaml", "src/data/garages/details/wand.yaml", "src/data/garages/details/holz-kipptor.yaml", "src/data/garages/details/satteldach.yaml", "src/data/garages/details/entwaesserung.yaml", "src/data/garages/details/lueftungsoeffnungen.yaml", "src/data/garages/details/dachbekiesung.yaml"]
  }, {
    "type": "colors",
    "slug": "farbgestaltung",
    "title": "Farbgestaltung für Sektionaltore und Nebentüren",
    "spacing": {
      "top": ["32", null, "40", null, "48", null, "64"],
      "bottom": ["32", null, "40", null, "48", null, "64"]
    },
    "text": "Die doppelwandigen LPU-Sektionaltore und Nebentüren erhalten Sie serienmäßig in Verkehrsweiß RAL 9016 sowie zusätzlich in 13 preiswerten Super-Color-Farben und rund 200 Farben nach RAL* sowie in vielen NCS und DB Farben.",
    "color_groups": "src/data/garages/colors/colors.yaml"
  }, {
    "type": "faqs",
    "slug": "wissenswertes",
    "title": "Wissenswertes",
    "faqs": ["src/data/garages/faqs/bauantrag.yaml", "src/data/garages/faqs/unterkellern.yaml", "src/data/garages/faqs/daemmen.yaml", "src/data/garages/faqs/ueberdachen.yaml", "src/data/garages/faqs/hang.yaml", "src/data/garages/faqs/carport.yaml", "src/data/garages/faqs/terasse.yaml", "src/data/garages/faqs/raum.yaml", "src/data/garages/faqs/bauen.yaml", "src/data/garages/faqs/kosten.yaml", "src/data/garages/faqs/spezialmass.yaml", "src/data/garages/faqs/garagentor.yaml", "src/data/garages/faqs/tiere.yaml"]
  }]
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      